import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
    style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getIllustrationsPath('14.png') + ')' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("a", {
        href: "#",
        class: "mb-12"
      }, [
        _createElementVNode("img", {
          alt: "Logo",
          src: "media/logos/logo-1.svg",
          class: "h-45px"
        })
      ], -1)),
      _createVNode(_component_router_view)
    ]),
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex flex-center flex-column-auto p-10\"><div class=\"d-flex align-items-center fw-bold fs-6\"><a href=\"#\" class=\"text-muted text-hover-primary px-2\">About</a><a href=\"#\" class=\"text-muted text-hover-primary px-2\">Contact</a><a href=\"#\" class=\"text-muted text-hover-primary px-2\">Contact Us</a></div></div>", 1))
  ], 4))
}